.path-0 {
  animation: pathAnim-0 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-0 {
  0% {
    d: path(
      "M 0,600 C 0,600 0,120 0,120 C 50.33301270193611,117.8236034036256 100.66602540387223,115.6472068072512 148,119 C 195.33397459612777,122.3527931927488 239.66891108644717,131.2347761746208 301,130 C 362.33108891355283,128.7652238253792 440.6583302503391,117.41368849426563 503,112 C 565.3416697496609,106.58631150573437 611.6977679121964,107.11046984831668 665,109 C 718.3022320878036,110.88953015168332 778.5505981008755,114.14443211246765 828,122 C 877.4494018991245,129.85556788753235 916.0998396843014,142.31180170181278 968,135 C 1019.9001603156986,127.6881982981872 1085.0500431619187,100.60836108028118 1141,88 C 1196.9499568380813,75.39163891971882 1243.6999876680234,77.25475397706252 1292,85 C 1340.3000123319766,92.74524602293748 1390.1500061659883,106.37262301146873 1440,120 C 1440,120 1440,600 1440,600 Z"
    );
  }
  25% {
    d: path(
      "M 0,600 C 0,600 0,120 0,120 C 64.21566161055618,145.54213836477987 128.43132322111236,171.08427672955975 175,154 C 221.56867677888764,136.91572327044025 250.49036872610674,77.20503144654087 293,75 C 335.50963127389326,72.79496855345913 391.60720187446054,128.09559748427674 453,150 C 514.3927981255395,171.90440251572326 581.0808237760513,160.4125786163522 632,145 C 682.9191762239487,129.5874213836478 718.0695030213344,110.25408805031446 772,120 C 825.9304969786656,129.74591194968554 898.6411641386112,168.57106918238995 951,163 C 1003.3588358613888,157.42893081761005 1035.36584042422,107.46163522012579 1092,93 C 1148.63415957578,78.53836477987421 1229.8954741645086,99.58238993710691 1292,110 C 1354.1045258354914,120.41761006289309 1397.0522629177458,120.20880503144654 1440,120 C 1440,120 1440,600 1440,600 Z"
    );
  }
  50% {
    d: path(
      "M 0,600 C 0,600 0,120 0,120 C 42.872758663213716,111.10213343198915 85.74551732642743,102.20426686397829 145,97 C 204.25448267357257,91.79573313602171 279.890689357504,90.28506597607597 329,92 C 378.109310642496,93.71493402392403 400.6917252435565,98.65546923171783 456,107 C 511.3082747564435,115.34453076828217 599.3424096682699,127.09305709705265 654,120 C 708.6575903317301,112.90694290294735 729.9386360833643,86.97230238007153 778,77 C 826.0613639166357,67.02769761992847 900.9030459982735,73.01773338266122 964,95 C 1027.0969540017265,116.98226661733878 1078.4491799235418,154.9567640892835 1132,158 C 1185.5508200764582,161.0432359107165 1241.3002343075595,129.1552102602047 1293,117 C 1344.6997656924405,104.84478973979529 1392.3498828462202,112.42239486989764 1440,120 C 1440,120 1440,600 1440,600 Z"
    );
  }
  75% {
    d: path(
      "M 0,600 C 0,600 0,120 0,120 C 61.05246022937477,91.87465778764334 122.10492045874955,63.7493155752867 175,78 C 227.89507954125045,92.2506844247133 272.6327783943766,148.8773954864965 325,156 C 377.3672216056234,163.1226045135035 437.36396596374414,120.74110247872733 489,108 C 540.6360340362559,95.25889752127267 583.9113577506473,112.15819459859418 633,113 C 682.0886422493527,113.84180540140582 736.9906030336664,98.62611912689603 791,89 C 845.0093969663336,79.37388087310397 898.1262301146874,75.33732889382166 950,84 C 1001.8737698853126,92.66267110617834 1052.5044765075843,114.02456529781725 1108,117 C 1163.4955234924157,119.97543470218275 1223.855863854976,104.56440991490936 1280,102 C 1336.144136145024,99.43559008509064 1388.0720680725121,109.71779504254532 1440,120 C 1440,120 1440,600 1440,600 Z"
    );
  }
  100% {
    d: path(
      "M 0,600 C 0,600 0,120 0,120 C 50.33301270193611,117.8236034036256 100.66602540387223,115.6472068072512 148,119 C 195.33397459612777,122.3527931927488 239.66891108644717,131.2347761746208 301,130 C 362.33108891355283,128.7652238253792 440.6583302503391,117.41368849426563 503,112 C 565.3416697496609,106.58631150573437 611.6977679121964,107.11046984831668 665,109 C 718.3022320878036,110.88953015168332 778.5505981008755,114.14443211246765 828,122 C 877.4494018991245,129.85556788753235 916.0998396843014,142.31180170181278 968,135 C 1019.9001603156986,127.6881982981872 1085.0500431619187,100.60836108028118 1141,88 C 1196.9499568380813,75.39163891971882 1243.6999876680234,77.25475397706252 1292,85 C 1340.3000123319766,92.74524602293748 1390.1500061659883,106.37262301146873 1440,120 C 1440,120 1440,600 1440,600 Z"
    );
  }
}

.path-1 {
  animation: pathAnim-1 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-1 {
  0% {
    d: path(
      "M 0,600 C 0,600 0,240 0,240 C 50.191589591811564,226.46161055617216 100.38317918362313,212.9232211123443 156,203 C 211.61682081637687,193.0767788876557 272.65887285731907,186.76872610679487 328,208 C 383.34112714268093,229.23127389320513 432.9813293871007,278.001874460476 484,278 C 535.0186706128993,277.998125539524 587.415809594278,229.223776051301 649,226 C 710.584190405722,222.776223948699 781.3554322357874,265.1030213343199 828,268 C 874.6445677642126,270.8969786656801 897.1624614625723,234.3641386114194 947,227 C 996.8375385374277,219.6358613885806 1073.9947219139228,241.4404242200025 1132,256 C 1190.0052780860772,270.5595757799975 1228.8586508817364,277.8741645085707 1277,274 C 1325.1413491182636,270.1258354914293 1382.570674559132,255.06291774571466 1440,240 C 1440,240 1440,600 1440,600 Z"
    );
  }
  25% {
    d: path(
      "M 0,600 C 0,600 0,240 0,240 C 52.55651744974719,255.06745591318287 105.11303489949438,270.13491182636574 161,279 C 216.88696510050562,287.86508817363426 276.10437785176964,290.52780860771986 326,271 C 375.89562214823036,251.47219139228014 416.469453693427,209.75385374275498 463,195 C 509.530546306573,180.24614625724502 562.0178073745221,192.4567764212603 616,216 C 669.9821926254779,239.5432235787397 725.4593168084845,274.41904057220376 785,284 C 844.5406831915155,293.58095942779624 908.1449253915403,277.8670612899248 955,256 C 1001.8550746084597,234.13293871007525 1031.9609816253546,206.11271426809716 1092,215 C 1152.0390183746454,223.88728573190284 1242.0111481070417,269.6820816376865 1305,280 C 1367.9888518929583,290.3179183623135 1403.9944259464792,265.1589591811568 1440,240 C 1440,240 1440,600 1440,600 Z"
    );
  }
  50% {
    d: path(
      "M 0,600 C 0,600 0,240 0,240 C 55.98515229991368,250.0124306326304 111.97030459982736,260.0248612652608 163,265 C 214.02969540017264,269.9751387347392 260.10393390060426,269.91298557158717 307,271 C 353.89606609939574,272.08701442841283 401.61395979775557,274.3231964483906 457,277 C 512.3860402022444,279.6768035516094 575.4402269083735,282.79422863485024 628,264 C 680.5597730916265,245.2057713651498 722.6251325687507,204.4998890122086 773,209 C 823.3748674312493,213.5001109877914 882.0592428166236,263.20621531631525 936,265 C 989.9407571833764,266.79378468368475 1039.1378961647551,220.6752497225305 1094,213 C 1148.8621038352449,205.3247502774695 1209.3891725243554,236.0927857935627 1268,247 C 1326.6108274756446,257.9072142064373 1383.3054137378222,248.95360710321864 1440,240 C 1440,240 1440,600 1440,600 Z"
    );
  }
  75% {
    d: path(
      "M 0,600 C 0,600 0,240 0,240 C 59.553927734615854,239.02123566407693 119.10785546923171,238.04247132815388 166,232 C 212.8921445307683,225.95752867184612 247.12250585768902,214.85135035146135 304,205 C 360.877494142311,195.14864964853865 440.4021211000123,186.55212726600075 501,203 C 561.5978788999877,219.44787273399925 603.2690097422616,260.9401405845357 642,279 C 680.7309902577384,297.0598594154643 716.5218399309409,291.68731039585646 780,281 C 843.4781600690591,270.31268960414354 934.6436305339744,254.31061783203847 988,235 C 1041.3563694660256,215.68938216796153 1056.9036379331608,193.07021827598965 1108,200 C 1159.0963620668392,206.92978172401035 1245.7418177333827,243.40850906400294 1307,255 C 1368.2581822666173,266.59149093599706 1404.1290911333085,253.29574546799853 1440,240 C 1440,240 1440,600 1440,600 Z"
    );
  }
  100% {
    d: path(
      "M 0,600 C 0,600 0,240 0,240 C 50.191589591811564,226.46161055617216 100.38317918362313,212.9232211123443 156,203 C 211.61682081637687,193.0767788876557 272.65887285731907,186.76872610679487 328,208 C 383.34112714268093,229.23127389320513 432.9813293871007,278.001874460476 484,278 C 535.0186706128993,277.998125539524 587.415809594278,229.223776051301 649,226 C 710.584190405722,222.776223948699 781.3554322357874,265.1030213343199 828,268 C 874.6445677642126,270.8969786656801 897.1624614625723,234.3641386114194 947,227 C 996.8375385374277,219.6358613885806 1073.9947219139228,241.4404242200025 1132,256 C 1190.0052780860772,270.5595757799975 1228.8586508817364,277.8741645085707 1277,274 C 1325.1413491182636,270.1258354914293 1382.570674559132,255.06291774571466 1440,240 C 1440,240 1440,600 1440,600 Z"
    );
  }
}

.path-2 {
  animation: pathAnim-2 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-2 {
  0% {
    d: path(
      "M 0,600 C 0,600 0,360 0,360 C 42.20742385004317,361.116142557652 84.41484770008634,362.23228511530397 141,367 C 197.58515229991366,371.76771488469603 268.54803304969784,380.18700209643606 325,368 C 381.45196695030216,355.81299790356394 423.39302010112215,323.01970649895185 469,315 C 514.6069798988779,306.98029350104815 563.8798865458133,323.7341719077568 620,338 C 676.1201134541867,352.2658280922432 739.0874337156247,364.043605870021 799,363 C 858.9125662843753,361.956394129979 915.7703785916881,348.0914046121593 974,335 C 1032.229621408312,321.9085953878407 1091.8310519176225,309.5907756813417 1136,318 C 1180.1689480823775,326.4092243186583 1208.905413737822,355.5454926624738 1257,366 C 1305.094586262178,376.4545073375262 1372.547293131089,368.22725366876307 1440,360 C 1440,360 1440,600 1440,600 Z"
    );
  }
  25% {
    d: path(
      "M 0,600 C 0,600 0,360 0,360 C 59.82266617338759,379.9369589345172 119.64533234677518,399.8739178690344 166,404 C 212.35466765322482,408.1260821309656 245.2413367862868,396.4412874583796 291,380 C 336.7586632137132,363.5587125416204 395.3893205080774,342.3609322974473 457,334 C 518.6106794919226,325.6390677025527 583.2013811814035,330.11498335183137 634,334 C 684.7986188185965,337.88501664816863 721.8051547663091,341.17913429522747 770,341 C 818.1948452336909,340.82086570477253 877.5779997533606,337.16847946725863 937,340 C 996.4220002466394,342.83152053274137 1055.8828462202491,352.1469478357381 1113,357 C 1170.1171537797509,361.8530521642619 1224.8906153656433,362.2437291897891 1279,362 C 1333.1093846343567,361.7562708102109 1386.5546923171783,360.87813540510547 1440,360 C 1440,360 1440,600 1440,600 Z"
    );
  }
  50% {
    d: path(
      "M 0,600 C 0,600 0,360 0,360 C 61.62826489086201,343.83055863854975 123.25652978172403,327.66111727709955 182,328 C 240.74347021827597,328.33888272290045 296.60214576396595,345.1860895301516 344,348 C 391.39785423603405,350.8139104698484 430.3348871624122,339.59452460229375 476,352 C 521.6651128375878,364.40547539770625 574.0583055863855,400.43581206067336 623,404 C 671.9416944136145,407.56418793932664 717.4318904920459,378.6622271550129 772,372 C 826.5681095079541,365.3377728449871 890.2141324454309,380.9152793192749 946,385 C 1001.7858675545691,389.0847206807251 1049.7115797262302,381.67665556788756 1112,384 C 1174.2884202737698,386.32334443211244 1250.9395486496485,398.378098409175 1308,396 C 1365.0604513503515,393.621901590825 1402.5302256751756,376.8109507954125 1440,360 C 1440,360 1440,600 1440,600 Z"
    );
  }
  75% {
    d: path(
      "M 0,600 C 0,600 0,360 0,360 C 58.65418670612901,372.3204340855839 117.30837341225802,384.6408681711678 161,391 C 204.69162658774198,397.3591318288322 233.42069305709703,397.75696140091253 291,381 C 348.579306942903,364.24303859908747 435.0088543593539,330.3312862251819 499,335 C 562.9911456406461,339.6687137748181 604.5438895054876,382.9178936983598 654,387 C 703.4561104945124,391.0821063016402 760.8155876186955,355.9971389813788 810,352 C 859.1844123813045,348.0028610186212 900.1937600197309,375.0935503761253 946,380 C 991.8062399802691,384.9064496238747 1042.4093723023802,367.62865951412016 1101,362 C 1159.5906276976198,356.37134048587984 1226.1687507707486,362.39181156739426 1284,364 C 1341.8312492292514,365.60818843260574 1390.9156246146258,362.8040942163029 1440,360 C 1440,360 1440,600 1440,600 Z"
    );
  }
  100% {
    d: path(
      "M 0,600 C 0,600 0,360 0,360 C 42.20742385004317,361.116142557652 84.41484770008634,362.23228511530397 141,367 C 197.58515229991366,371.76771488469603 268.54803304969784,380.18700209643606 325,368 C 381.45196695030216,355.81299790356394 423.39302010112215,323.01970649895185 469,315 C 514.6069798988779,306.98029350104815 563.8798865458133,323.7341719077568 620,338 C 676.1201134541867,352.2658280922432 739.0874337156247,364.043605870021 799,363 C 858.9125662843753,361.956394129979 915.7703785916881,348.0914046121593 974,335 C 1032.229621408312,321.9085953878407 1091.8310519176225,309.5907756813417 1136,318 C 1180.1689480823775,326.4092243186583 1208.905413737822,355.5454926624738 1257,366 C 1305.094586262178,376.4545073375262 1372.547293131089,368.22725366876307 1440,360 C 1440,360 1440,600 1440,600 Z"
    );
  }
}

.path-3 {
  animation: pathAnim-3 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pathAnim-3 {
  0% {
    d: path(
      "M 0,600 C 0,600 0,480 0,480 C 52.53634233567641,465.58386977432485 105.07268467135282,451.16773954864965 167,459 C 228.92731532864718,466.83226045135035 300.24560365026514,496.91291157972614 346,515 C 391.75439634973486,533.0870884202739 411.9449007275866,539.1806141324455 462,520 C 512.0550992724134,500.8193858675545 591.9747934393883,456.3646318904921 659,452 C 726.0252065606117,447.6353681095079 780.1559255148601,483.3608583055864 818,494 C 855.8440744851399,504.6391416944136 877.4015045011714,490.1919348871624 933,473 C 988.5984954988286,455.8080651128376 1078.238056480454,435.87140214576397 1139,449 C 1199.761943519546,462.12859785423603 1231.6462695770133,508.3224565297817 1277,519 C 1322.3537304229867,529.6775434702183 1381.1768652114934,504.83877173510916 1440,480 C 1440,480 1440,600 1440,600 Z"
    );
  }
  25% {
    d: path(
      "M 0,600 C 0,600 0,480 0,480 C 34.494758909853246,473.118756936737 68.98951781970649,466.2375138734739 130,469 C 191.0104821802935,471.7624861265261 278.5366876310273,484.16870144284127 343,482 C 407.4633123689727,479.83129855715873 448.8637316561844,463.0876803551609 500,465 C 551.1362683438156,466.9123196448391 612.0083857442349,487.4805771365149 667,496 C 721.9916142557651,504.5194228634851 771.1027253668764,500.9900110987791 822,485 C 872.8972746331236,469.0099889012209 925.58071278826,440.5593784683685 970,453 C 1014.41928721174,465.4406215316315 1050.5744234800839,518.7724750277469 1107,519 C 1163.4255765199161,519.2275249722531 1240.1215932914045,466.35072142064377 1299,451 C 1357.8784067085955,435.64927857935623 1398.9392033542977,457.8246392896781 1440,480 C 1440,480 1440,600 1440,600 Z"
    );
  }
  50% {
    d: path(
      "M 0,600 C 0,600 0,480 0,480 C 59.1775558021951,496.76635836724626 118.3551116043902,513.5327167344925 176,513 C 233.6448883956098,512.4672832655075 289.75710938463436,494.63549142927616 338,494 C 386.24289061536564,493.36450857072384 426.6164508570723,509.925317548403 486,512 C 545.3835491429277,514.074682451597 623.7770871870761,501.6632383771119 668,497 C 712.2229128129239,492.3367616228881 722.2752003946233,495.42172894314956 773,499 C 823.7247996053767,502.57827105685044 915.122111234431,506.6498458502897 976,502 C 1036.877888765569,497.3501541497103 1067.2363546676531,483.9788876556913 1116,476 C 1164.7636453323469,468.0211123443087 1231.9324700949562,465.4346035269453 1289,467 C 1346.0675299050438,468.5653964730547 1393.0337649525218,474.2826982365274 1440,480 C 1440,480 1440,600 1440,600 Z"
    );
  }
  75% {
    d: path(
      "M 0,600 C 0,600 0,480 0,480 C 50.034134911826385,458.9571587125416 100.06826982365277,437.9143174250832 155,437 C 209.93173017634723,436.0856825749168 269.7610556172154,455.2998890122087 319,479 C 368.2389443827846,502.7001109877913 406.8875077074855,530.8861265260821 463,514 C 519.1124922925145,497.11387347391786 592.6889135528426,435.15560488346284 647,436 C 701.3110864471574,436.84439511653716 736.3568380811444,500.49145394006655 778,503 C 819.6431619188556,505.50854605993345 867.8837341225799,446.87857935627085 932,442 C 996.1162658774201,437.12142064372915 1076.1082254285363,485.9942286348501 1130,495 C 1183.8917745714637,504.0057713651499 1211.6833641632752,473.14450610432857 1259,464 C 1306.3166358367248,454.85549389567143 1373.1583179183624,467.4277469478357 1440,480 C 1440,480 1440,600 1440,600 Z"
    );
  }
  100% {
    d: path(
      "M 0,600 C 0,600 0,480 0,480 C 52.53634233567641,465.58386977432485 105.07268467135282,451.16773954864965 167,459 C 228.92731532864718,466.83226045135035 300.24560365026514,496.91291157972614 346,515 C 391.75439634973486,533.0870884202739 411.9449007275866,539.1806141324455 462,520 C 512.0550992724134,500.8193858675545 591.9747934393883,456.3646318904921 659,452 C 726.0252065606117,447.6353681095079 780.1559255148601,483.3608583055864 818,494 C 855.8440744851399,504.6391416944136 877.4015045011714,490.1919348871624 933,473 C 988.5984954988286,455.8080651128376 1078.238056480454,435.87140214576397 1139,449 C 1199.761943519546,462.12859785423603 1231.6462695770133,508.3224565297817 1277,519 C 1322.3537304229867,529.6775434702183 1381.1768652114934,504.83877173510916 1440,480 C 1440,480 1440,600 1440,600 Z"
    );
  }
}
