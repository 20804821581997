.spacing {
  z-index: 1;
  position: relative;
}

.left {
  text-align: start;
  padding-left: 7%;
  color: #f0f0f0;
}

.subtitle {
  color: olivedrab;

  padding-left: 7%;
  padding-right: 5%;
}

.explore {
  width: 85%;
}

.tablestyle {
  margin: 3% 7%;
}

.padleft {
  padding-left: 7%;
}
.padding {
  margin: 3% 5%;
}
