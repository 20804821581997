.footercss {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: grey;
  color: white;
}
.text{
    display: flex;
    justify-content: center;
    align-items: center;
}