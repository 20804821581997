.padding {
  padding-left: 13%;
  padding-right: 13%;
  padding-top: 3%;
  padding-bottom: 10%;
  height: 83vh;
  margin-bottom: 10;
}

.explorecard {
  margin-top: 2rem;
  font-size: 1.5rem;
}

.explorebtn {
  margin-top: 0.5rem;
}

.title {
  margin-bottom: 1rem;
}

.grey {
  color: grey;
}
