.padding {
  padding-left: 13%;
  padding-right: 13%;
  padding-top: 3%;

 
}

.content{
  padding-bottom: 10%;
}
