.padding {
  padding: 3% 15%;
  height: 83vh;
}

.title {
  margin-bottom: 1rem;
}

.grey {
  color: grey;
}

.cardpad {
  margin-top: 2rem;
  border: 2px solid black;
  border-radius: 1rem;
  padding: 3%;
}

.extrapad {
  padding-bottom: 10%;
}
